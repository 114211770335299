import axios from "axios";
import { baseUrl, config, headers } from "../config/env";

export function createPost(data) {
  return axios.post(baseUrl + `/post`, data, config);
}
export function fetchPostList(params) {
  return axios.get(baseUrl + `/post/list`, { params, headers });
}
export function fetchPostDetail(id) {
  return axios.get(baseUrl + `/post/${id}`, { headers });
}
