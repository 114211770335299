<template>
  <div class="modal qna">
    <div class="modal_body">
      <div class="flexB title">
        <h3>문의 답변</h3>
        <button class="btn" @click="closeModal2">
          <span></span>
        </button>
      </div>
      <div class="content">
        <article>
          <div class="flexB">
            <p>{{ qnaData.title }}</p>
            <span>{{
              moment(qnaData.createdAt).format("YYYY.MM.DD HH:mm")
            }}</span>
          </div>
          <pre>{{ qnaData.content }}</pre>
        </article>
        <article class="reply">
          <div class="flexB">
            <p>답변 내용</p>
            <span v-if="qnaData.status">{{
              moment(qnaData.updatedAt).format("YYYY.MM.DD HH:mm")
            }}</span>
          </div>
          <pre>{{
            qnaData.replys && qnaData.replys[0]
              ? qnaData.replys[0].contents
              : "답변 대기중..."
          }}</pre>
        </article>
      </div>
      <!-- <div class="buttonWrap">
        <button class="close" @click="closeModal2">확인</button>
      </div> -->
    </div>
    <div class="modal_bg" @click="closeModal2"></div>
  </div>
</template>

<script>
// import { createNote } from "@/api/note";
import moment from "moment";
export default {
  props: ["qnaData"],
  data() {
    return {
      moment: moment,
      noteContent: "",
    };
  },

  methods: {
    closeModal2() {
      this.$emit("close");
    },
    // submit() {
    //   if (this.noteContent == "") {
    //     return alert("쪽지 내용을 입력해 주세요.");
    //   }
    //   let data = {
    //     contents: this.noteContent,
    //     user: this.author._id,
    //   };
    // },
  },
};
</script>
