export const verifyPassword = {
  methods: {
    verifyPassword(str) {
      let pw = str;
      let num = pw.search(/[0-9]/g);
      let eng = pw.search(/[a-z]/gi);

      if (pw.length < 6 || pw.length > 12) {
        alert("비밀번호는 6자리 ~ 12자리 이내로 입력해주세요.");
        return false;
      } else if (pw.search(/₩s/) != -1) {
        alert("비밀번호는 공백없이 입력해주세요.");
        return false;
      } else if (num < 0 || eng < 0) {
        alert("비밀번호는 영문,숫자를 혼합하여 입력해주세요.");
        return false;
      } else return true;
    },
  },
};
